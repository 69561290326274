import _objectSpread from "/Users/itnew000/Desktop/ofht20230915/htcode/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _toConsumableArray from "/Users/itnew000/Desktop/ofht20230915/htcode/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import { resourceTypeCheckboxType } from "../../assets/js/data";
import { columnDrawer } from "../../data/learn/learn";
import SearchSelect from "../../components/common/Search";
import CommonDrawer from '../../components/common/CommonDrawer';
import { uploadFile } from '../../api/qiniuApI';
export default {
  name: "LearnColumn",
  data: function data() {
    return {
      changeColumnState: false,
      //是否展示切换栏目弹框
      columnTypeDatalist: [],
      //栏目分组数据
      isEdit: false,
      //是否编辑
      columnData: {
        //添加结构数据
        name: "",
        //阶段
        logo: "",
        //图片
        columnTypeId: "",
        //类型id
        pid: "",
        //父级ID
        pName: "",
        //父级名称
        rank: 1,
        //排列顺序
        state: 1 //状态：1正常 -1禁用
      },

      //添加结构、修改结构的数据
      columnDrawer: columnDrawer,
      //树形结构数据
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      currentTree: this.$route.query.tree ? JSON.parse(this.$route.query.tree) : {},
      //左边菜单是否折叠
      isCollapse: false,
      //树形结构数据
      treeData: [],
      // 筛选
      filter: [{
        placeholder: '栏目状态',
        value: '',
        type: 'select',
        list: [{
          id: '1',
          name: '正常'
        }, {
          id: '-1',
          name: '禁用'
        }]
      }, {
        placeholder: '栏目名称',
        value: '',
        type: 'text',
        list: []
      }],
      sum: "",
      //类型循环的所有id加起来
      resourceTypeCheckboxType: resourceTypeCheckboxType,
      resourceTypeCheckboxTypeList: _toConsumableArray(resourceTypeCheckboxType.list),
      //类型循环的数据
      // 用户信息
      userInfo: this.Ls.getParseToken("nof_userInfo"),
      // List接口返回表格数据
      tableKey: 1,
      //表格的key
      tableData: [],
      total: 0,
      //页数总数
      currentPage: 1,
      // 当前页数
      pageSize: 20,
      //每页个数
      // drawer抽屉-数据
      drawer: false,
      //是否显示弹框
      direction: "rtl",
      drawerState: "",
      // dialog弹框-删除弹框
      delId: "",
      centerDialogVisible: false,
      // 删除弹框显示隐藏
      logoDialogVisible: false // 封面删除弹框显示隐藏
    };
  },

  watch: {
    '$route': 'changeRouter'
  },
  components: {
    SearchSelect: SearchSelect,
    CommonDrawer: CommonDrawer
  },
  created: function created() {
    //获取树状菜单
    // this.coursestructureGetAll();
    this.resourcetypeGetAll();
    //获取数据
    this.initData();
    //获取栏目分类下拉框
    this.columnGetType();
  },
  methods: {
    changeColumnCancel: function changeColumnCancel() {
      this.changeColumnState = false;
    },
    changeColumnConfirm: function changeColumnConfirm() {
      var _this2 = this;
      this.service.axiosUtils({
        requestName: 'columnChangeColumn',
        data: {
          id: this.currentColumnValue.id,
          pId: this.setAffiliationColumn.id
        },
        sucFn: function sucFn(res) {
          if (res.status == 1) {
            _this2.$toast.success("修改成功");
            _this2.changeRouter();
            //隐藏弹框
            _this2.changeColumnCancel();
          } else {
            _this2.$toast.error(res.message);
          }
        }
      });
    },
    //显示切换所属栏目
    showChangeColumn: function showChangeColumn(value) {
      this.currentColumnValue = value;
      this.changeColumnState = true;
    },
    changeColumn: function changeColumn() {},
    //切换栏目点击
    treeClick: function treeClick(data) {
      this.setAffiliationColumn = data;
    },
    //传递完封面的回调函数
    setLogo: function setLogo(cover, row) {
      /*      this.currentRow=row;
            this.formFile={
              ...this.currentRow
            }
            this.currentRow.cover=cover;
            // console.log("this.currentRow",this.currentRow)
            if(this.currentRow.url){
              //传资源文件的话要保证content为空
              this.formFile.content="";
              let arr=[{
                url:this.currentRow.url,
                size:this.currentRow.size,
                name:this.currentRow.name,
                duration:this.currentRow.duration
              }]
              this.formFile.urlList=arr;
            }else{
              //传图文的话urllist要保证为空
              this.formFile.urlList=[];
            }
      
      
            this.formFile.cover=cover;*/
      this.currentRow = row;
      this.currentRow.logo = cover;

      //调用修改封面接口
      this.editColumnLogo(cover, row);
    },
    //修改栏目封面
    editColumnLogo: function editColumnLogo(cover, row) {
      var _this3 = this;
      this.service.axiosUtils({
        requestName: 'editColumnLogo',
        data: {
          id: row.id,
          logo: cover
        },
        sucFn: function sucFn(res) {
          if (res.status == 1) {
            _this3.$toast.success("添加封面成功");
          } else {
            _this3.currentRow.logo = "";
            _this3.$toast.error(res.message);
          }
        }
      });
    },
    //修改图标
    changeFile: function changeFile(file, fileList) {
      var _this = this;
      _this.file = file.raw;
      var reader = new FileReader();
      reader.readAsDataURL(file.raw);
      reader.onload = function (e) {
        // console.log(e)
        _this.columnData.logo = this.result;
      };
    },
    //路由改变执行的方法
    changeRouter: function changeRouter(json) {
      //再重新设置路由
      // this.$emit('navigation',this.navData);

      if (!json) {
        this.currentTree = this.$route.query.tree ? JSON.parse(this.$route.query.tree) : {};
      }
      this.columnData.pId = this.currentTree.id;
      // console.log("路由改变后的tree",this.currentTree)
      this.filter[1].value = this.$route.query.resName || "";
      this.filter[0].value = this.$route.query.state || "";
      this.filterJson = {
        resName: this.filter[1].value,
        state: this.filter[0].value
      };

      //初始化路由
      // this.initPathFn();

      //获取数据，刷新列表
      // this.reloadList();

      this.initData();
    },
    //给其他组件使用的设置当前的tree
    setCurrentTree: function setCurrentTree(value) {
      this.currentTree = value;
    },
    //获取树状结构
    resourcetypeGetAll: function resourcetypeGetAll() {
      var _this4 = this;
      this.service.axiosUtils({
        requestName: 'columnGetAll',
        data: {},
        sucFn: function sucFn(res) {
          // this.resourcetypeDrop=res.data;
          var allTree = [{
            id: 0,
            name: "所有栏目",
            children: res.data
          }];
          _this4.treeData = allTree;
          if (!_this4.$route.query.tree) {
            _this4.currentTree = _this4.treeData[0];
            _this4.changeRouter(_this4.currentTree);
          } else {
            // this.treeData=res.data;
            _this4.currentTree = JSON.parse(_this4.$route.query.tree);
            _this4.changeRouter();
          }
          _this4.$nextTick(function () {
            _this4.$refs.tree.setCurrentKey(_this4.currentTree.id);
          });
        }
      });
    },
    //获取父级的下拉框
    columnGetType: function columnGetType() {
      var _this5 = this;
      var postData = {};
      this.service.axiosUtils({
        requestName: 'columntypeGetAll',
        data: postData,
        sucFn: function sucFn(res) {
          // var resData=res.data;
          /*          if(resData.length>0 && resData[0].pName){
                      for (let i = 0; i < resData.length; i++) {
                        resData[i].name=resData[i].name+"-"+resData[i].pName
                      }
                    }*/
          // console.log(res.data);
          _this5.columnTypeDatalist = res.data;
          _this5.columnTypeDatalist.push({
            id: 0,
            name: "无"
          });
          // console.log("数组",this.columnTypeDatalist)
          /*this.columnDrawer.list[2].drop.list.push({
            id:0,
            name:"无",
            value:0
          })*/
        }
      });
    },
    //获取左边的树状结构 - 其他页面的 暂时没用到
    coursestructureGetAll: function coursestructureGetAll(postJson) {
      var _this6 = this;
      var postData = {
        courseId: this.$route.query.courseId
      };
      //如果有搜索字段的话就加上
      if (postJson) {
        postData.structureName = postJson.structureName;
      }
      this.service.axiosUtils({
        requestName: 'coursestructureGetAll',
        data: postData,
        sucFn: function sucFn(res) {
          _this6.treeData = res.data;
          //赋值当前课程信息
          _this6.courseTree = res.data[0];

          //设置下最初的path
          _this6.diskNavData = _this6.courseTree.name + ">" + _this6.courseTree.id;
          // delete this.courseTree.sublevel
          if (!_this6.$route.query.tree) {
            _this6.currentTree = _this6.treeData[0];
            _this6.changeRouter(_this6.currentTree);
          } else {
            _this6.currentTree = JSON.parse(_this6.$route.query.tree);
            _this6.changeRouter();
          }
          _this6.$nextTick(function () {
            _this6.$refs.tree.setCurrentKey(_this6.currentTree.id);
          });
        }
      });
    },
    //点击某一个节点
    handleNodeClick: function handleNodeClick(data) {
      this.currentTree = _objectSpread({}, data);
      delete this.currentTree.children;

      //删除树状菜单的子级，否则会因为数据太多挂到地址栏超出地址栏的最大字符导致数据不全的问题
      // this.currentTree.courseId=this.$route.query.courseId
      // this.currentTree.pageSize=10;
      // this.currentTree.pageIndex=1;
      // this.currentTree.structureId=this.currentTree.id;

      var queryData = _objectSpread({}, this.$route.query);
      queryData.tree = JSON.stringify(this.currentTree);
      this.$router.replace({
        path: this.$route.path,
        query: queryData
      });
    },
    //点击展开收起
    isCollapseFn: function isCollapseFn() {
      if (this.isCollapse) {
        this.isCollapse = false;
      } else {
        //展开
        this.isCollapse = true;
      }
    },
    //筛选功能
    search: function search(filterArr) {
      var _this7 = this;
      this.service.axiosUtils({
        requestName: "columnGetList",
        data: {
          name: filterArr[1].value,
          state: filterArr[0].value,
          // userId:this.userInfo.userId,
          // roleId:this.userInfo.roleId,
          pageSize: this.pageSize,
          pageIndex: this.currentPage
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this7.tableData = res.data.data;
            _this7.total = res.data.total;
            // this.initType();
          } else {
            _this7.$message.error(res.message);
          }
        }
      });
    },
    //重置
    reload: function reload() {
      this.filter[0].value = "";
      this.filter[1].value = "";
      this.initData();
    },
    //类型选择有改变的时候
    checkedItem: function checkedItem(value) {
      // console.log(this.columnData);
      // this.resourceTypeCheckboxTypeList[]
    },
    // 添加函数
    addFun: function addFun() {
      // this.clearcolumnData();
      // this.initType();
      this.drawerState = "add";
      this.drawer = true;

      //显示抽屉弹框
      // this.$refs.commonDrawer.drawer=true;
    },
    // 初始化数据
    initData: function initData() {
      var _this8 = this;
      this.service.axiosUtils({
        requestName: "columnGetList",
        data: {
          columnId: this.currentTree.id,
          pageSize: this.pageSize,
          pageIndex: this.currentPage
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this8.tableData = res.data.data;
            _this8.total = res.data.total;
            // this.initType();
          } else {
            _this8.$message.error(res.message);
          }
        }
      });
    },
    //新建初始化数据
    clearcolumnData: function clearcolumnData() {
      this.columnData = {
        name: "",
        code: "",
        rank: "",
        state: "",
        type: ""
      };
      // this.initType();
      this.$refs.form.resetFields();
    },
    //初始化type
    /*  initType(){
        //判断选中的类型
        this.sum=0;
        for (let i = 0; i < this.resourceTypeCheckboxType.list.length; i++) {
          let id=this.resourceTypeCheckboxType.list[i].id;
          // this.sum+=id;
          //初始化数据
          this.resourceTypeCheckboxTypeList[i].isChecked=false;
        }
        this.columnData.type=this.sum;
      },*/
    // page-页码修改pagesize
    handleSizeChange: function handleSizeChange(page) {
      this.pageSize = page;
      this.initData();
    },
    // page-页码修改当前页码
    handleCurrentChange: function handleCurrentChange(page) {
      this.currentPage = page;
      this.initData();
    },
    // table-修改正常/禁用状态
    editState: function editState(row) {
      var _this9 = this;
      var rowState = "";
      if (row.state === 1) {
        rowState = -1;
      } else {
        rowState = 1;
      }
      this.service.axiosUtils({
        requestName: "columnChangeState",
        data: {
          userId: this.userInfo.userId,
          roleId: this.userInfo.roleId,
          id: row.id,
          state: rowState
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this9.$toast.success(res.message);
            if (row.state === 1) row.state = -1;else row.state = 1;
          } else {
            _this9.$toast.error(res.message);
          }
        }
      });
    },
    // table-中操作函数
    detail: function detail(type, val) {
      var _this10 = this;
      if (type === "edit") {
        this.drawerState = "edit";
        this.drawer = true;
        setTimeout(function () {
          var pName = _this10.columnData.pName;
          _this10.columnData = {
            name: val.name,
            logo: val.logo,
            columnTypeId: val.columnTypeId,
            pId: val.pId,
            pName: pName,
            rank: val.rank,
            state: val.state,
            id: val.id
          };
          //转换type
          /*for (let i = 0; i < this.resourceTypeCheckboxTypeList.length; i++) {
            let id = this.resourceTypeCheckboxTypeList[i].id;
            if((this.columnData.type&id) > 0){
              this.resourceTypeCheckboxTypeList[i].isChecked=true;
            }else{
              this.resourceTypeCheckboxTypeList[i].isChecked=false;
            }
          }*/
        }, 30);
      } else if (type === "del") {
        this.centerDialogVisible = true;
        this.delId = val.id;
      } else {}
    },
    //删除封面
    deleteLogo: function deleteLogo(row) {
      this.currentRow = row;
      //删除封面
      this.logoDialogVisible = true;
      // this.dialog.title="删除封面";
      // this.dialog.content="确定删除 <span style='color: #3f86f8;'>"+row.name+"</span> 的封面图吗？";
    },
    deleteLogoFn: function deleteLogoFn() {
      var _this11 = this;
      //deleteColumnLogo
      var row = this.currentRow;
      this.service.axiosUtils({
        requestName: "deleteColumnLogo",
        data: {
          id: row.id
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this11.logoDialogVisible = false;
            _this11.$toast.success("删除封面成功");
            _this11.initData();
            if (row.state === 1) row.state = -1;else row.state = 1;
          } else {
            _this11.$toast.error(res.message);
          }
        }
      });
    },
    // drawer抽屉-取消函数
    cancelClick: function cancelClick() {
      /*      this.$nextTick(function () {
              this.$refs["form"].resetFields();
            });*/
      this.$refs["form"].resetFields();
      this.drawer = false;
    },
    //传递的参数处理转换
    /*    setPostData(){
          this.columnData.type=0;
          for (let i = 0; i < this.resourceTypeCheckboxTypeList.length; i++) {
            if(this.resourceTypeCheckboxTypeList[i].isChecked == true){
              this.columnData.type+=this.resourceTypeCheckboxTypeList[i].id;
            }
          }
        },*/
    // drawer抽屉-确定函数
    //添加课程结构定义确定
    /*    confirmClick (valid, formRef, formData) {
          // alert(111111)
          console.log("this.columnData",this.columnData);
          var _this=this;
          // console.log(this.file)
          if(this.file){
            uploadFile({
              folderName: "logo",
              complete(res,url){
                _this.columnData.logo=url;
                _this.addEditColumn(valid, formRef, formData);
              }
            },this.file);
          }else{
            _this.addEditColumn(valid, formRef, formData);
          }
        },*/
    confirmClick: function confirmClick(formName) {
      var _this = this;
      // console.log(this.file)
      if (this.file) {
        uploadFile({
          folderName: "column",
          complete: function complete(res, url) {
            _this.columnData.logo = url;
            // _this.addEditColumn(valid, formRef, formData);
            _this.addEditColumn(formName);
          }
        }, this.file);
      } else {
        _this.addEditColumn(formName);
        // _this.addEditColumn(valid, formRef, formData);
      }
    },
    addEditColumn: function addEditColumn(formName) {
      var that = this;
      // this.setPostData();
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var url = "";
          if (that.drawerState === "edit") {
            //修改
            url = "columnEditColumn";
          } else {
            //添加
            url = "columnAddColumn";
            delete that.columnData.id;
            that.columnData.pId = that.currentTree.id;
            that.columnData.pName = that.currentTree.name;
          }
          /*          if (this.columnData.type == 0){
                      this.$toast.warning("至少保留一个类型")
                      return;
                    }*/
          that.service.axiosUtils({
            requestName: url,
            data: that.columnData,
            sucFn: function sucFn(res) {
              if (res.status === 1) {
                that.$toast.success(res.message);
                that.drawer = false;
                that.clearcolumnData();
                if (that.drawerState != "edit") {
                  // that.currentPage = 1;
                  //更新树状菜单
                  that.resourcetypeGetAll();
                }
                that.$nextTick(function () {
                  that.$refs["form"].resetFields();
                });
                that.initData();
              } else {
                that.$toast.error(res.message);
              }
            }
          });
        }
      });
    },
    //添加修改
    /*    addEditColumn(valid, formRef, formData){
          if (valid) {
            let requestName = "";
            if (this.isEdit) {
              requestName = "columnEditColumn";
            } else {
              //添加
              requestName = "columnAddColumn"
            }
    
            // alert(requestName);
    /!*
            if(this.currentFilterJson && this.currentFilterJson.valueJson && (this.currentFilterJson.index == this.checkStructureN()-1)){
              this.columnData.pid=this.currentFilterJson.valueJson.id;
            }
    *!/
    
            this.service.axiosUtils({
              requestName: requestName,
              data: this.columnData,
              sucFn: res => {
                if (res.status == 1) {
                  this.file="";
                  this.drawer = false;
                  this.$toast.success(res.message);
                  this.$refs.commonDrawer.drawer = false;
                  formRef.resetFields();
                  //重新拉取列表
                  this.initData();
                } else {
                  this.$toast.error(res.message)
                }
              }
            })
            alert("结束");
          }
        },*/
    // dialog弹框-删除弹框确定函数
    confirmFun: function confirmFun() {
      var _this12 = this;
      //删除当前的资源类型
      this.service.axiosUtils({
        requestName: "columnDeleteColumn",
        data: {
          id: this.delId
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this12.$toast.success(res.message);
            _this12.delId = "";
            _this12.centerDialogVisible = false;
            //更新树状菜单
            _this12.resourcetypeGetAll();
            _this12.initData();
          } else {
            _this12.$toast.error(res.message);
          }
        }
      });
    }
  }
};